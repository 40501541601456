import { Box, CircularProgress, IconButton, Modal, Stack, Typography } from '@mui/joy';
import {
  GigBaseItem,
  Skill,
  IPaginator,
  GigPreviewItemContext,
  ToastMode,
  PayRange,
  FilterPanel,
  JobType,
  LocationFilter,
  City,
} from '../../../../types/interfaces';
import { getSkills } from '../../../../api/skill';
import { Colors, FlexAlign, FontSizes } from '../../../../theme';
import GigPreviewItem from '../../../../components/GigPreviewItem/index';
import ThinGrayDarkLine from '../../../../components/ThinGrayDarkLine';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import useMobileMode from '../../../../hooks/useMobileMode';
import { Button } from '@mui/material';
import Autocomplete from '@mui/joy/Autocomplete';
import { getAllGigsByFilters } from '../../../../api/gigs';
import { FilterIcon, SearchIcon } from '../../../../assets/svg';
import { useEffect, useRef, useState } from 'react';
import { uniqBy } from 'lodash';
import LoadMoreButton from '../../../../components/LoadMoreButton';
import { WEB_NAVIGATION_BAR_WIDTH } from '../../../../config/constants';
import GigPlaceholder from '../../../../assets/images/gig-placeholder.png';
import GigDetails from '../../../GigDetails';
import triggerToast from '../../../../utils/triggerToast';
import useAnalyticsPageView from '../../../../hooks/useAnalyticsPageView';
import analytics from '../../../../utils/analytics';
import errorReporting from '../../../../utils/errorReporting';
import GigFilterListItem from '../../../../components/GigFilterListItem';
import GigLocationFilterBody from '../../../../components/GigLocationFilterBody';
import GigJobTypeFilterBody from '../../../../components/GigJobTypeFilterBody';
import GigPayRangeFilterBody from '../../../../components/GigPayRangeFilterBody';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import useIsAnonymous from '../../../../hooks/useIsAnonymous';
import { getCities } from '../../../../api/cities';

export const MaxHourlyPayRange = 200;
export const MaxYearlyPayRange = 500000;
export const YearlyToHourlyConversionRate = 2000;

export const PayRangeHourlyResetPayload = {
  min: 0,
  max: MaxHourlyPayRange,
  mode: 'hourly',
} as PayRange;

export const JobTypeResetPayload = {
  partTime: true,
  fullTime: false,
};

export const PayRangeYearlyResetPayload = {
  min: 0,
  max: MaxYearlyPayRange,
  mode: 'yearly',
} as PayRange;

const reverseGeocode = async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.GOOGLE_MAPS_API_KEY}`,
    );
    const data = await response.json();

    if (data.status === 'OK' && data.results && data.results.length > 0) {
      const addressComponents = data.results[0].address_components;
      let city, state;

      for (let component of addressComponents) {
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name;
        }
      }

      if (city && state) {
        return `${city} - ${state}`;
      }
    } else {
      console.error('Reverse geocoding failed:', data.status);
    }
  } catch (error) {
    console.error('Error during reverse geocoding:', error);
  }
};

const getUserLocation = async (): Promise<{ latitude: number; longitude: number } | null> => {
  if (!navigator.geolocation) {
    console.error('Geolocation is not supported by this browser.');
    return null;
  }

  try {
    const position = await new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
  } catch (error) {
    console.error("Error getting user's location:", error);
    return null;
  }
};

const FindGigs = () => {
  useAnalyticsPageView('FindGigs Tab');
  const isAnonymous = useIsAnonymous();

  const [gigs, setGigs] = useState<GigBaseItem[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [paginator, setPaginator] = useState<IPaginator>();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [locationFilter, setLocationFilter] = useState<LocationFilter>({
    zipCode: null,
    city: 'Baltimore - Maryland',
    mode: 'city',
  });
  const [jobTypeFilter, setJobTypeFilter] = useState<JobType>(JobTypeResetPayload);
  const [payRangeFilter, setPayRangeFilter] = useState<PayRange>(PayRangeHourlyResetPayload);
  const [isSubmittingFilters] = useState(false);
  const [currentFilterPanelOpen, setCurrentFilterPanelOpen] = useState<FilterPanel | null>(null);

  const closeFilterBody = () => setCurrentFilterPanelOpen(null);
  const closeFilterModal = () => setIsFilterModalOpen(false);

  const filterModalRef = useRef(null);
  useOutsideClick(filterModalRef, ['skip-outside-click-callback'], closeFilterModal);

  const resetPayRangeFilter = () => {
    setPayRangeFilter(PayRangeHourlyResetPayload);
  };

  const resetLocationFilter = () => {
    setLocationFilter({ zipCode: null, city: null, mode: 'city' });
  };

  const resetJobTypeFilter = () => {
    setJobTypeFilter(JobTypeResetPayload);
  };

  const resetFilters = () => {
    resetLocationFilter();
    resetJobTypeFilter();
    resetPayRangeFilter();
  };

  const resetAll = () => {
    setPaginator(undefined);
    setCurrentPage(1);
    setGigs([]);
    setSelectedSkills([]);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getCities();
        const cityNames = res?.data?.cities.map((city: City) => city.name);

        const userCoordinates = await getUserLocation();
        if (userCoordinates === null) return;

        const storedLocationData = localStorage.getItem('userLocation');

        let locationData;
        let city;
        let isStoredLocationValid = false;

        if (storedLocationData) {
          locationData = JSON.parse(storedLocationData);
          const createdAt = new Date(locationData.createdAt);
          const now = new Date();
          // one week
          const expirationTimeInSeconds = 60 * 60 * 24 * 7;
          const secondsDiff = (now.getTime() - createdAt.getTime()) / 1000;

          if (secondsDiff < expirationTimeInSeconds) {
            city = locationData.city;
            isStoredLocationValid = true;
          }
        }

        if (isStoredLocationValid === false && userCoordinates) {
          city = await reverseGeocode({
            latitude: userCoordinates.latitude,
            longitude: userCoordinates.longitude,
          });
        }

        if (city) {
          locationData = {
            city,
            latitude: userCoordinates.latitude,
            longitude: userCoordinates.longitude,
            createdAt: new Date().toISOString(),
          };
          localStorage.setItem('userLocation', JSON.stringify(locationData));
        }

        if (city && cityNames.includes(city)) {
          setLocationFilter({
            ...locationFilter,
            city: city,
          });
        }
      } catch (error) {
        errorReporting.captureException(error, {
          level: 'error',
        });
      }
    })();
  }, []);

  /**
   * @description When the selected skills change, the paginator
   * nad the current page would be outdated as it would refer
   * to a db result that now has different filters
   */
  const paginatorAwareSetSelectedSkills = (value: Skill[]) => {
    resetAll();
    setSelectedSkills(value);
  };

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const locationFilterComputedLabel =
    locationFilter.mode === 'city' ? locationFilter.city : locationFilter.zipCode;
  const payRangeFilterComputedLabel = `$${payRangeFilter.min} - $${payRangeFilter.max} ${payRangeFilter.mode}`;
  const jobTypeFilterComputedLabel = `${jobTypeFilter.partTime ? 'Part Time' : ''} ${
    jobTypeFilter.partTime && jobTypeFilter.fullTime ? ' - ' : ''
  } ${jobTypeFilter.fullTime ? 'Full Time' : ''}`;

  useEffect(() => {
    (async () => {
      try {
        const res = await getSkills();
        setSkills(res?.data?.skills);
      } catch (error) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        gigs?.length ? setBtnLoading(true) : setLoading(true);
        const res = await getAllGigsByFilters({
          page: currentPage,
          skills: [],
          hourlyPayRange: {
            min:
              payRangeFilter.mode === 'hourly'
                ? payRangeFilter.min
                : payRangeFilter.min / YearlyToHourlyConversionRate,
            max:
              payRangeFilter.mode === 'hourly'
                ? payRangeFilter.max
                : payRangeFilter.max / YearlyToHourlyConversionRate,
          },
          location: {
            city: locationFilter.city,
            zipCode: locationFilter.zipCode,
            mode: locationFilter.mode,
          },
          jobType: jobTypeFilter,
          isAnonymous,
        });

        if (Array.isArray(res?.data?.gigs)) {
          setGigs(uniqBy([...gigs, ...res?.data?.gigs], 'id'));
          setPaginator(res?.data?.paginator);
        }
      } catch (error) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });
      } finally {
        setLoading(false);
        setBtnLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, skills]);

  /**
   * @description This method filters gigs by skills
   */
  const onSearch = async ({ skills }: { skills: Skill[] }) => {
    try {
      setLoading(true);
      const res = await getAllGigsByFilters({
        page: currentPage,
        skills: skills.map((skill) => skill.id),
        hourlyPayRange: {
          min:
            payRangeFilter.mode === 'hourly'
              ? payRangeFilter.min
              : payRangeFilter.min / YearlyToHourlyConversionRate,
          max:
            payRangeFilter.mode === 'hourly'
              ? payRangeFilter.max
              : payRangeFilter.max / YearlyToHourlyConversionRate,
        },
        location: {
          city: locationFilter.city,
          zipCode: locationFilter.zipCode,
          mode: locationFilter.mode,
        },
        jobType: jobTypeFilter,
        isAnonymous,
      });

      if (Array.isArray(res?.data?.gigs)) {
        setGigs(res.data.gigs);
        setPaginator(res?.data?.paginator);
      }
      analytics.track('Search For Gigs');
    } catch (error: any) {
      console.log('error', error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while searching gigs. Please retry or contact support.',
      });
    } finally {
      setLoading(false);
    }
  };

  const height = useWindowHeight();
  const width = useWindowWidth();
  const mobileMode = useMobileMode();

  const [modalGigId, setModalGigId] = useState<string | null>(null);
  const closeGigDetailsModal = () => setModalGigId(null);

  const MobileWidth = width - 32;
  const WebWidth = width - 32 - WEB_NAVIGATION_BAR_WIDTH;

  const ResponsiveWidth = mobileMode ? MobileWidth : WebWidth;

  return (
    <Stack
      direction="column"
      sx={{ ...styles.container, height: mobileMode ? height - 55 : height }}
    >
      {modalGigId && (
        <Modal open>
          <GigDetails gigIdFromProp={modalGigId} goBack={closeGigDetailsModal} />
        </Modal>
      )}

      {isFilterModalOpen && (
        <Stack
          ref={filterModalRef}
          sx={mobileMode ? styles.filterModalContainerMobileMode : styles.filterModalContainer}
        >
          {currentFilterPanelOpen === null && (
            <>
              <Stack
                direction="row"
                sx={
                  mobileMode
                    ? { ...styles.filterModalContainerMobile, width: ResponsiveWidth - 8 }
                    : styles.filterModalHeaderContainer
                }
              >
                <Typography sx={styles.filterModalLabel}>Filter</Typography>
                <Box sx={styles.filteredItemsCountContainer}>
                  <Typography sx={styles.filteredItemsCount}>{gigs.length}</Typography>
                </Box>
                <Box onClick={resetFilters} sx={styles.filterModalResetButtonContainer}>
                  <Typography sx={styles.filterModalResetButton}>Reset</Typography>
                </Box>
              </Stack>

              <Stack
                direction="column"
                sx={
                  mobileMode
                    ? { ...styles.filtersListContainerMobile, width: ResponsiveWidth - 8 }
                    : styles.filtersListContainer
                }
              >
                <GigFilterListItem
                  onClick={() => setCurrentFilterPanelOpen(FilterPanel.Location)}
                  filterName={FilterPanel.Location}
                  filterContent={
                    locationFilterComputedLabel ? locationFilterComputedLabel : 'No Location Filter'
                  }
                />

                <Box sx={styles.filterSpacer} />

                <GigFilterListItem
                  onClick={() => setCurrentFilterPanelOpen(FilterPanel.JobType)}
                  filterName={FilterPanel.JobType}
                  filterContent={
                    jobTypeFilterComputedLabel ? jobTypeFilterComputedLabel : 'No Job Type Filter'
                  }
                />

                <Box sx={styles.filterSpacer} />

                <GigFilterListItem
                  onClick={() => setCurrentFilterPanelOpen(FilterPanel.PayRange)}
                  filterName={FilterPanel.PayRange}
                  filterContent={payRangeFilterComputedLabel}
                />
              </Stack>

              <Button
                type="submit"
                sx={(theme) => ({
                  backgroundColor: Colors.Blue,
                  color: Colors.White,
                  '&.Joy-disabled': {
                    color: Colors.White,
                  },
                  marginTop: '16px',
                  borderRadius: 0,
                  fontWeight: 700,
                  fontSize: '15px',
                  textTransform: 'none',
                  width: mobileMode ? ResponsiveWidth - 32 : undefined,
                })}
                disabled={isSubmittingFilters}
                variant="contained"
                onClick={() => {
                  onSearch({ skills: selectedSkills });
                  closeFilterModal();
                }}
              >
                {isSubmittingFilters ? (
                  <CircularProgress variant="solid" color="primary" size="sm" />
                ) : (
                  'Submit'
                )}
              </Button>
            </>
          )}
          {currentFilterPanelOpen === FilterPanel.Location && (
            <GigLocationFilterBody
              onBack={closeFilterBody}
              onReset={resetLocationFilter}
              filterName={'Location'}
              filterContent={locationFilter}
              setFilterContent={setLocationFilter}
              containerStyle={mobileMode ? { width: ResponsiveWidth - 8, paddingTop: '16px' } : {}}
            />
          )}
          {currentFilterPanelOpen === FilterPanel.JobType && (
            <GigJobTypeFilterBody
              filterName="Job Type"
              filterContent={jobTypeFilter}
              setFilterContent={setJobTypeFilter}
              onReset={resetJobTypeFilter}
              onBack={closeFilterBody}
              containerStyle={mobileMode ? { width: ResponsiveWidth - 8, paddingTop: '16px' } : {}}
            />
          )}
          {currentFilterPanelOpen === FilterPanel.PayRange && (
            <GigPayRangeFilterBody
              filterName={'Pay Range'}
              filterContent={payRangeFilter}
              setFilterContent={setPayRangeFilter}
              onBack={closeFilterBody}
              onReset={resetPayRangeFilter}
              containerStyle={mobileMode ? { width: ResponsiveWidth - 8, paddingTop: '16px' } : {}}
            />
          )}
        </Stack>
      )}

      <Stack direction="row" sx={{}} mt={4} gap={2}>
        <Stack direction="row" sx={{ position: 'relative', width: ResponsiveWidth - 60 }}>
          <Autocomplete
            startDecorator={<SearchIcon />}
            multiple
            freeSolo
            limitTags={6}
            placeholder="Skills"
            options={skills}
            onChange={(_, value: (Skill | string)[], reason) => {
              if (reason === 'clear') {
                resetAll();
                return;
              }

              if (reason === 'removeOption' && selectedSkills.length > 1) {
                resetAll();
                paginatorAwareSetSelectedSkills([...(value as Skill[])]);
                onSearch({ skills: [...value] as Skill[] });
              }

              if (reason === 'removeOption' && (value as Skill[]).length === 0) {
                resetAll();
                paginatorAwareSetSelectedSkills([...value] as Skill[]);
                onSearch({ skills: [...value] as Skill[] });
              }

              paginatorAwareSetSelectedSkills(value as Skill[]);
            }}
            getOptionLabel={(option: any) => option.name}
            value={selectedSkills}
            sx={{
              flex: 1,
              paddingBlock: '12px',
              borderColor: '#82DA9D',
              borderRadius: '6px',
              paddingRight: '90px',
              backgroundColor: 'transparent',
            }}
          />

          <Button
            variant="contained"
            onClick={() => onSearch({ skills: selectedSkills })}
            disabled={selectedSkills.length === 0}
            style={{
              minHeight: '32px',
              height: '32px',
              marginRight: '8px',
              position: 'absolute',
              right: 1,
              top: '50%',
              transform: 'translateY(-50%)',
              textTransform: 'none',
            }}
            sx={{
              color: Colors.White,
              borderRadius: '4px',
              backgroundColor: Colors.Blue,
              marginLeft: '8px',
            }}
          >
            Find
          </Button>
        </Stack>
        <IconButton
          color="primary"
          aria-label="filter gigs"
          component="label"
          sx={{
            all: 'unset',
            display: 'grid',
            placeItems: 'center',
            width: 40,
            borderRadius: 0,
            mx: 'auto',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'unset',
            },
          }}
          onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
        >
          <FilterIcon />
        </IconButton>
      </Stack>

      <Box
        sx={{
          marginTop: '16px',
          width: ResponsiveWidth,
          display: 'flex',
          justifyContent: loading ? 'center' : 'flex-start',
        }}
      >
        {loading ? (
          <CircularProgress size={'sm'} />
        ) : (
          <Typography
            sx={{
              ...FontSizes.Header3W700,
              color: Colors.Black,
              marginTop: '16px',
              marginLeft: '4px',
            }}
          >
            {`${gigs.length} search results`}
          </Typography>
        )}
      </Box>

      {gigs.map((gig, index) => (
        <Box key={index} sx={{ width: ResponsiveWidth }}>
          <GigPreviewItem
            key={gig.id}
            id={gig.id}
            name={gig.name}
            isExternal={gig.isExternal}
            imageUrl={gig.featuredImage || GigPlaceholder}
            location={gig.location}
            hourlyRate={gig.hourlyRate}
            clientRating={undefined}
            status={gig.status}
            estimatedPrice={gig.estimatedPrice}
            ISO8601DateTimeStart={gig.startDateAndTime}
            ISO8601DateTimeEnd={gig.endDateAndTime}
            city={gig.city}
            bookmarked={gig.bookmarked}
            applicationStatus={gig.applicationStatus}
            componentContext={GigPreviewItemContext.FindGigs}
            handleClick={() => {
              setModalGigId(gig.id);
            }}
            isAnonymous={isAnonymous}
            zipCode={gig.zipCode}
            isFulltime={gig.isFulltime}
          />
          {index !== gigs.length - 1 && <ThinGrayDarkLine />}
        </Box>
      ))}

      <LoadMoreButton onClick={handleLoadMore} loading={btnLoading} next={paginator?.next} />
    </Stack>
  );
};

export default FindGigs;

const styles = {
  container: {
    ...FlexAlign.JustifyStartAlignCenter,
    width: '100%',
    overflowY: 'scroll',
    backgroundColor: Colors.GrayLight,
    boxShadow: '0 5px 20px rgba(96, 100, 112, 0.16)',
  },
  filtersListContainer: {
    marginTop: '16px',
  },
  filtersListContainerMobile: {
    marginTop: '16px',
  },
  filterModalContainerMobileMode: {
    ...FlexAlign.JustifyStartAlignCenter,
    width: '100%',
    overflowY: 'scroll',
    backgroundColor: Colors.White,
    position: 'fixed',
    zIndex: 10,
    bottom: 0,
    paddingBottom: '48px',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
  },
  filterModalResetButton: {
    ...FontSizes.ButtonSmallW700,
    color: Colors.Red,
  },
  filterModalLabel: {
    ...FontSizes.Header2W700,
  },
  filterModalHeaderContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterModalContainer: {
    position: 'absolute',
    top: 80,
    right: 25,
    width: 350,
    backgroundColor: Colors.White,
    zIndex: 1,
    borderRadius: '16px',
    padding: '16px',
  },
  filterModalContainerMobile: {
    backgroundColor: 'white',
    padding: '16px',
    width: '100%',
  },
  filterModalResetButtonContainer: {
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginRight: '-8px',
  },
  filterSpacer: {
    marginTop: '16px',
    width: '100%',
  },
  filteredItemsCountContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  filteredItemsCount: {
    height: '24px',
    minWidth: '24px',
    verticalAlign: 'center',
    textAlign: 'center',
    borderRadius: 9999,
    backgroundColor: Colors.Red,
    ...FontSizes.ButtonSmallW700,
    lineHeight: '24px',
    marginLeft: '8px',
    color: Colors.White,
  },
};
